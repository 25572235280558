import '../style/footer.css';

export function Footer () {
    return(
        <footer className="footer">
            <p className="text-footer">
                &#10047; Made with Love &#10047;
            </p>
        </footer>
    );
}
import '../style/landingSection.css';

export function LandingSection({ isTron }) {

    return (
        <div className="landing-page">
            {
                !isTron ?
                    <svg className={"name-border"} xmlns="http://www.w3.org/2000/svg" width="1054" height="408" viewBox="0 0 1054 408" fill="none">
                        <path d="M0.624613 94.9993C0.284051 84.5302 55.5974 89.8028 59.2101 82.4454C61.2744 78.2412 35.6717 58.3833 41.4252 52.1063C49.0458 43.7923 75.102 62.7094 79.0874 58.3833C81.9709 55.2535 81.6774 0.714437 89.1007 0.844011C96.524 0.973585 100.377 55.1547 103.149 58.3833C106.981 62.8459 131.095 44.8371 138.719 51.0602C146.342 57.2832 120.587 78.1717 122.503 82.4454C125.857 89.9244 965.205 87.153 965.205 94.9993C965.205 105.984 126.116 100.719 122.503 108.076C120.439 112.281 145.191 135.022 138.719 138.938C129.07 144.777 108.181 128.335 104.195 132.661C101.312 135.791 100.534 312.948 89.1007 312.948C81.6763 312.948 80.8137 135.89 78.0413 132.661C74.2092 128.199 54.003 146.156 44.5636 139.985C38.2322 135.845 65.3112 112.35 63.3948 108.076C60.0411 100.597 0.965177 105.469 0.624613 94.9993Z" fill="#FDBA35"/>
                        <path d="M1053.68 312.948C1054.02 323.417 998.709 318.145 995.096 325.502C993.032 329.706 1018.63 349.564 1012.88 355.841C1005.26 364.155 979.204 345.238 975.219 349.564C972.335 352.694 972.629 407.233 965.205 407.104C957.782 406.974 953.929 352.793 951.157 349.564C947.325 345.102 923.211 363.11 915.587 356.887C907.964 350.664 933.719 329.776 931.803 325.502C928.449 318.023 89.1007 320.795 89.1007 312.948C89.1007 301.964 928.19 307.229 931.803 299.871C933.867 295.667 909.115 272.925 915.587 269.009C925.236 263.171 946.125 279.612 950.111 275.286C952.994 272.156 953.772 94.9993 965.205 94.9993C972.63 94.9993 973.493 272.058 976.265 275.286C980.097 279.749 1000.3 261.791 1009.74 267.963C1016.07 272.103 988.995 295.597 990.911 299.871C994.265 307.35 1053.34 302.479 1053.68 312.948Z" fill="#FDBA35"/>
                    </svg>
                : null
            }
            {
                !isTron ?
                    <h1 className="name-bar outline outline-thick">Sofi Bogert</h1>
                    : null
            }
            <h1 className="name-bar">Sofi Bogert</h1>
        </div>
    );
}